import type { SeoData, SeoMetaSocial } from "~/modules/shared/ts/SeoData";

interface MetaEntry {
  name?: string;
  property?: string;
  content: string;
}

const getOgTags = (seoData: SeoData) => {
  const meta: MetaEntry[] = [];

  seoData.metaTitle &&
    meta.push({ property: "og:site_name", content: seoData.metaTitle });

  seoData.metaImage &&
    meta.push({
      property: "og:image",
      content: seoData.metaImage.data?.attributes.url || "",
    });

  return meta;
};
const getSocialTags = (metaSocial: SeoMetaSocial[]) => {
  const meta: MetaEntry[] = [];

  if (!metaSocial.length) return meta;

  metaSocial.forEach((singleMetaSocial) => {
    const handle =
      singleMetaSocial.socialNetwork === "Twitter" ? "twitter" : "og";

    if (singleMetaSocial.socialNetwork === "Twitter") {
      meta.push({ name: "twitter:card", content: "summary" });
      meta.push({ name: "twitter:site", content: "@apeblock" });
    }

    meta.push({ name: `${handle}:title`, content: singleMetaSocial.title });
    meta.push({
      name: `${handle}:description`,
      content: singleMetaSocial.description,
    });
    singleMetaSocial.image.data &&
      meta.push({
        name: `${handle}:image`,
        content: singleMetaSocial.image.data.attributes.url,
      });
  });

  return meta;
};

export const useSeoData = (
  seoData: SeoData,
  additionalMeta: MetaEntry[] = [{ property: "og:type", content: "website" }],
) => {
  const meta: MetaEntry[] = [
    ...getOgTags(seoData),
    ...getSocialTags(seoData.metaSocial || []),
    ...additionalMeta,
  ];
  const script = [];
  const link = [];

  seoData.metaDescription &&
    meta.push({ name: "description", content: seoData.metaDescription });
  seoData.keywords &&
    meta.push({ name: "keywords", content: seoData.keywords });
  seoData.metaRobots &&
    meta.push({ name: "robots", content: seoData.metaRobots });
  seoData.metaViewport &&
    meta.push({ name: "viewport", content: seoData.metaViewport });

  seoData.structuredData &&
    script.push({
      type: "application/ld+json",
      children: JSON.stringify(seoData.structuredData),
    });

  seoData.canonicalURL &&
    link.push({
      rel: "canonical",
      href: seoData.canonicalURL,
    });

  return {
    title: seoData.metaTitle || "",
    meta,
    script,
    link,
  };
};
